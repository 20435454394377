abbr[title],
acronym[title] {
  text-decoration: none;
}

.react-calendar {
  font-family: 'Rubik-Regular';
  width: auto;
}

.react-calendar__navigation {
  background-color: #fff;
  margin-bottom: 0;
}

.react-calendar__navigation button {
  min-width: unset;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus,
.react-calendar__navigation button[disabled] {
  background-color: #fff;
}

.react-calendar__navigation__arrow {
  background-color: #fff;
  font-size: 30px;
  color: #0248be;
}

.react-calendar__navigation__label,
.react-calendar__navigation button[disabled] {
  background-color: #fff;
  color: #242325;
  font-size: 18px;
  font-family: 'Rubik-Regular';
  text-align: center;
}

.react-calendar__month-view__weekdays {
  border-bottom: 1px solid #9b9b9b;
  color: #9b9b9b;
  margin-bottom: 10px;
}

.react-calendar__tile:disabled {
  color: #9b9b9b;
  cursor: not-allowed;
}

.react-calendar__tile--active {
  background-color: #6357ff;
  color: #fff !important;
}

.react-calendar__tile--active:enabled:hover {
  background-color: #e0ddff;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: #6357ff;
}
