.AccordionItem {
  margin-bottom: 32px;
}

.AccordionItem:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.AccordionItem:last-child {
  margin-bottom: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.AccordionTrigger {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: white;
}

.AccordionContent {
  overflow: hidden;
  background-color: white;
}

.AccordionContent[data-state='open'] {
  animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.AccordionContent[data-state='closed'] {
  animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.AccordionArrow {
  color: #d8d8d8;
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.AccordionItem[data-state='open'] .AccordionArrow {
  transform: rotate(180deg);
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}
